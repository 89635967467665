import { ApolloClient } from "@apollo/client";
import Redis from "ioredis";
import { Cache } from "./Cache";
import { SiteLoader } from "./SiteLoader";
import { SiteRepository } from "./SiteRepository";

// Simple, server-side static cache of site repo.
let siteRepo: SiteRepository | undefined;

export const getSiteRepository = (client: ApolloClient<any>) => siteRepo = siteRepo ?? new SiteRepository(
    new SiteLoader(client),
    () => new Cache(
      process.env.REDIS_URL != null
        ? new Redis(process.env.REDIS_URL, { db: 0 })
        : new Redis({ db: 0 }),
    ),
    // Cache for 7 days by default.
    parseInt(process.env.SITE_CACHE_TTL ?? `${7 * 24 * 60 * 60}`, 10),
  );
